<template>
  <picture v-not-lazy
    v-if="props.src && props.notLazy"
    key="static"
    class="picture"
    data-not-lazy
  >
    <source :data-srcset="createSrcSet(props.src)" >
    <img v-lazy-load  :data-src="props.src" alt="" >
  </picture>

  <picture v-lazy-load  v-else-if="props.src" key="lazy" class="picture">
    <source :data-srcset="createSrcSet(props.src)" >
    <img v-lazy-load  :data-src="props.src" alt="" >
  </picture>
</template>

<script setup lang="ts">
  const props = defineProps<{
    src?: string,
    notLazy?: boolean
  }>();

  const { createSrcSet } = useProjectMethods();
</script>
